.cookies-infobar {
  &__content {
    background: #144089;
    color: #fff;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px 20px 0 0;
    padding: 30px 80px ;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 70px;
    font-size: 19px;
    line-height: 24px;
    z-index: 999999999;

    @include mq(md) {
      padding: 25px;
      font-size: 16px;
      line-height: 21px;
      flex-direction: column;
      gap:  0;
    }

    p {
      font-size: 16px;
      line-height: 20px;

      a {
        text-decoration: underline !important;
      }
    }
  }

  @include mq(md) {
    padding: 30px;
    font-size: 16px;
    line-height: 20px;
  }

  img {
    width: fit-content;
    margin-bottom: 15px;
  }

  &_btn {
    margin-top: 25px;
    background: $white;
    text-decoration: none;
    color: $black;

    img {
      filter: invert(0%) sepia(100%) saturate(25%) hue-rotate(358deg) brightness(0%) contrast(100%);
      margin-bottom: 0;
    }
  }
}

.cookies-infobar.cookies-infobar_accepted {
  display: none;
}

.darker {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($dark, .7);
  z-index: 999999;
}

.doctor-infobar {
  &__content {
    background: $white;
    color: $black;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    z-index: 999999999;
    text-align: center;
    max-width: 600px;

    @include mq(md) {
      padding: 20px;
      min-width: 90%;
    }
  }

  @include mq(md) {
    padding: 30px;
    font-size: 16px;
    line-height: 20px;
  }

  img {
    margin-bottom: 15px;
  }

  h2 {
    font-family: 'Besley', sans-serif;
  }

  p {
    font-size: 19px;
    line-height: 24px;
    font-family: 'Jost', sans-serif;
    color: rgba($black, .5);
  }

  &_wrapper {
    display: flex;
    gap: 20px;
    margin-top: 30px;
    justify-content: center;
  }

  form {
    margin-top: 35px;

    @include mq(md) {
      margin-top: 25px;
    }

    label {
      padding: 20px;
      background-color: #F6F4F8;
      display: flex;
      gap: 15px;
      text-align: left;
    }

    input {
      border: 1px solid #C7C7C7;
      height: 25px;
    }
  }
}

.doctor-infobar.doctor-infobar_accepted {
  display: none;
}

.darker {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($dark, .7);
  z-index: 999999;
}
