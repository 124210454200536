.materials {
  &__title {
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;

    @include mq(xxl, min) {
      margin-top: 30px;
    }
  }

  &__item {
    background-color: #00544E;
    height: 300px;
    position: relative;
    background-position: center right;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 50px;

    @include mq(md) {
      margin-bottom: 30px;
    }

    .text {
      position: absolute;
      top: 50%;
      left: 35px;
      transform: translate(0, -50%);
      color: $white;
      width: 70%;

      @include mq(md) {
        width: 75%;
        left: 25px;
      }

      .small {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 26px;
        color: rgba($white, .4);

        @include mq(md) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .title {
        font-size: 28px;
        line-height: 32px;
        font-weight: 600;
        font-family: "Besley", sans-serif;
        margin-bottom: 30px;

        @include mq(md) {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
  }
}