.article {
  position: relative;
  z-index: 9;

  h1 {
    font-family: 'Poppins', sans-serif;
  }

  .page {
    &__progress {
      display: flex;
      align-items: center;
      justify-items: center;
      width: fit-content;
      margin: 0 auto;
      position: relative;
      z-index: 9;

      &:first-of-type {
        margin-top: 15px;

        @include mq(md) {
          margin-top: 25px;
        }
      }

      .step {
        display: flex;
        align-items: center;
        padding: 12px 14px 8px;
        border-radius: 50%;
        background: $white;
        color: $secondary;
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;

        @include mq(md) {
          font-size: 12px;
          line-height: 12px;
          padding: 10px 12px 7px;
        }

        &:first-of-type {
          padding: 12px 16px 8px 14px;

          @include mq(md) {
            padding: 9px 12px 8px;
          }
        }

        &--inactive {
          color: #C0CED6;
          border: 2px solid #C0CED6;
          background-color: transparent;
        }
      }

      .line {
        width: 107px;
        height: 3px;
        background-color: $white;

        @include mq(md) {
          width: 55px;
        }
      }

      &--text {
        width: 45%;
        justify-content: space-between;
        margin: 6px auto 50px;

        @include mq(xxl, min) {
          width: 38%;
        }

        @include mq(md) {
          width: 100%;
          margin: 5px auto 20px;
        }

        p {
          width: 100px;
          text-align: center;
          color: $white;
          margin-bottom: 0;

          @include mq(md) {
            font-size: 14px;
          }
        }

        .inactive {
          color: #C0CED6;
        }
      }
    }
  }

  &__top {
    padding: 50px 0 70px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: $white;
    margin-bottom: 85px;
    text-align: center;

    @include mq(md) {
      padding: 40px 0;
    }

    img {
      margin-bottom: 45px;
    }

    .breadcrumbs {
      a,
      p {
        color: rgba($white, .45);
        display: flex;
        gap: 10px;
        transition: all .3s;

        &:hover {
          color: $third;
        }
      }

      .item {
        &:after {
          display: block;
          content: '>';
          width: 6px;
          height: 12px;
        }

        &:last-child {
          color: $white;

          &:after {
            display: none;
          }
        }
      }
    }
  }

  h1 {
    text-align: left;
  }

  &__author {
    padding: 30px 0;
    border-top: 1px solid rgba($white, .15);
    border-bottom: 1px solid rgba($white, .15);
    text-align: left;

    p {
      font-size: 20px;
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
      margin-bottom: 5px;
    }

    .desc {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 0;
    }
  }

  &__topImage {
    position: absolute;
    height: 350px;
    width: auto;
    bottom: 0;
    right: 7%;

    @include mq(md) {
      display: none;
    }
  }

  &__buttons {
    display: flex;
    gap: 20px;

    @include mq(md) {
      flex-direction: column;
      margin: 30px 0;

      .btn {
        margin: 0 !important;
      }
    }
  }

  &__background {
    background-color: $white;
    padding: 35px 38px;
    border-radius: 10px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 35px;
    font-size: 17px;
    line-height: 24px;

    @include mq(md) {
      padding: 25px;
    }

    .title {
      font-size: 20px;

      strong {
        color: $black;
      }
    }

    .quote {
      color: $primary;
      background-color: $third;
      padding: 17px 22px;
      font-size: 21px;
      line-height: 26px;
      font-weight: 600;
    }

    strong {
      color: $black;
    }

    .pismiennictwo {
      font-size: 12px;
      line-break: anywhere;
    }
  }

  &__spis {
    h2 {
      margin: 0;
      font-size: 28px;
      line-height: 32px;
      font-weight: 700;
    }
  }

  &__links {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    a {
      transition: all .2s;
      margin-bottom: 4px;
      padding: 18px 0;
      border-bottom: 1px solid #D5D5D5;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin-bottom: 0;
      }

      &:hover {
        color: $primary;

        p {
          text-decoration: underline;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      &:after {
        padding: 0 10px;
        display: block;
        content: '>';
        color: $white;
        font-size: 22px;
        font-weight: 500;
        background-color: $primary;
        border-radius: 50%;
        text-decoration: none !important;
      }
    }
  }

  &__content {
    padding-bottom: 30px;

    h2 {
      font-size: 28px;
      line-height: 32px;
    }

    h3 {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 20px;
    }

    ul,
    ol {
      li {
        margin-bottom: 15px;
      }
    }

    ol {
      color: rgba($black, .66);
    }

    .gray {
      color: rgba($black, .66);
    }

    img {
      margin: 20px 0 30px;
      width: 100%;
    }
  }

  &__small {
    font-size: 14px;
    color: rgba($black, .4);
  }

  .btn {
    margin: 40px 0;
  }

  &__sticky {
    height: 33%;
    margin-right: -70px;
    text-align: right;

    @include mq(md) {
      margin-right: 0;
    }

    img {
      height: 360px;
      width: auto;
      object-fit: contain;
      object-position: center;
      position: sticky;
      top: 120px;
    }
  }
}