.drug {

  h1 {
    margin: 65px 0 30px;
    text-align: center;
  }

  &__glik{
    margin: 40px 0!important;
  }

  &__content {
    margin-top: 60px;

    img {
      width: 70%;
    }

    .accordion-item {
      border-radius: 15px !important;
      border: none;
      margin-bottom: 25px;
      background-color: $white;

      &:first-of-type {
        .accordion-button {
          border-radius: 15px;

          &:not(.collapsed) {
            border-radius: 15px 15px 0 0;
          }
        }
      }

      &:last-of-type {
        .accordion-button.collapsed {
          border-radius: 15px;
        }
      }
    }

    .accordion-header {
      margin-top: 0;
      border-radius: 15px;
    }

    .accordion-button {
      font-size: 22px;
      font-weight: 600;
      background-color: $white;
      padding: 18px 25px;
      border-radius: 15px;

      @include mq(md) {
        font-size: 19px;
      }
    }

    .accordion-button:not(.collapsed) {
      background-color: #E6336D;
      color: $white;

    }

    .accordion-button:not(.collapsed)::after {
      filter: invert(97%) sepia(22%) saturate(0%) hue-rotate(305deg) brightness(356%) contrast(101%);
    }

    .accordion-body {
      padding: 20px 53px;
      font-size: 19px;
      line-height: 27px;
      font-weight: 300;

      & span{
        font-size: 20px;
        font-weight: 600;
      } 

      @include mq(md) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &__sita {
    .accordion-button:not(.collapsed) {
      background-color: #5D8B42;
      color: $white;
      border-radius: 15px 15px 0 0;
    }
  }

  &__refundacja {
    width: 40%;
  }
}