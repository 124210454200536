@import 'variables';

$breakpoints: '';
@if variable-exists(grid-breakpoints) {
    $breakpoints: $grid-breakpoints;
} @else {
    $breakpoints: (
        "xs": 0,
        "sm": 576px,
        "md": 768px,
        "lg": 992px,
        "xl": 1080px
    );
}

@mixin mq($width, $type: max) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

$horizontal-breakpoints: (
    "def":        451px,
);

@mixin hmq($height, $type: max) {
    @if map_has_key($horizontal-breakpoints, $height) {
        $height: map_get($horizontal-breakpoints, $height);
        @if $type == max {
            $height: $height - 1px;
        }
        @media only screen and (#{$type}-height: $height) {
            @content;
        }
    }
}

@mixin flex($justify) {
    display: flex;
    justify-content: $justify;
    align-items: center;
}

@mixin absolute($top: false, $right: false, $bottom: false, $left: false) {
    position: absolute;
    
    @if $top != false {
        top: $top;
    }
    
    @if $right != false {
        right: $right;
    }
    
    @if $bottom != false {
        bottom: $bottom;
    }

    @if $left != false {
        left: $left;
    }
}

@mixin ps-el() {
    display: block;
    content: "";
}

@mixin clearfix() {
    display: block;
    content: "";
    clear: both;
}

@mixin transition($elem){
    transition: $elem .35s ease-out;
}

@mixin letter-spacing($spacing) {
    letter-spacing: ($spacing / 1000) * 1em;
}