.splide__pagination {
  counter-reset: pagination-num;
}

.splide__pagination__page:before {
  counter-increment: pagination-num;
  content: counter( pagination-num );
}

.slider {
  &__items .splide__track {
    margin-right: calc((100vw - 100%)/-2);

    @include mq(md) {
      margin-right: unset;
    }
  }

  &__content {
    height: 100%;
    width: 500px;
    padding: 45px;
    position: relative;

    @include mq(md) {
      position: unset;
      width: 100%;
      height: 300px;
      padding: 30px;
    }

    h3 {
      font-family: 'Besley', sans-serif;
    }
  }

  &__text {
    color: $white;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    max-width: 420px;

    @include mq(md) {
      position: unset;
      top: unset;
      transform: unset;
    }

    h3 {
      margin-bottom: 15px;
      font-size: 38px;
      line-height: 48px;

      @include mq(md) {
        font-size: 28px;
        line-height: 38px;
      }
    }
  }

  &__desc {
    font-size: 20px;
    font-family: 'Jost', sans-serif;
    margin-bottom: 0;
    padding-right: 10px;

    @include mq(md) {
      font-size: 18px;
    }
  }

  &__img {
    height: 475px;
    object-fit: contain;
    transition: all .3s;

    &:hover {
      opacity: .85;
    }

    @include mq(xxl, min) {
      height: 550px;
    }

    &--content {
      object-fit: cover;
      width: 485px;

      &:hover {
        opacity: 1;
      }
    }

    @include mq(md) {
      width: auto;
      height: 100%;

      &--content {
        height: 300px;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &__icon {
    width: auto;
    height: 55px;

    @include mq(md) {
      height: 45px;
      margin-bottom: 15px;
    }
  }
}

.splide__arrows {
  position: absolute;
  left: 6%;
  bottom: -75px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  gap: 20px;

  @include mq(xxl, min) {
    max-width: 1400px;
    left: 14%;
  }

  @include mq(md) {
    justify-content: center;
  }
}

.splide__arrow {
  background-color: $secondary;
  border: 1px solid $secondary;
  color: $white;
  width: 130px;
  height: 50px;
  border-radius: 29px;
  padding: 16px 36px;
  position: unset;
  opacity: 1;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transform: unset;

  &--next {
    right: -5em;
  }

  &--prev {
    left: 0;
    background-color: transparent;
    color: $secondary;
  }
}