.btn {
  border-radius: 29px;
  padding: 17px 36px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  transition: 0.2s;
  font-family: 'Poppins', sans-serif;

  @include mq(md) {
    padding: 14px 25px;
    font-size: 14px;
  }

  &__primary {
    background-color: $secondary;
    border: 1px solid $secondary;
    color: $white;
    min-width: 150px;

    img {
      transition: all .3s;
      width: 9px !important;
      height: 14px !important;
    }

    &:hover {
      background-color: transparent;
      color: $secondary;
      border: 1px solid $secondary;

      img {
        filter: invert(85%) sepia(132%) saturate(4711%) hue-rotate(195deg) brightness(60%) contrast(84%)
      }
    }

    &:disabled {
      background-color: rgba($secondary, .75);
      color: rgba($white, .75);
    }

    &--quiz{
      &:hover{
        padding-left: 36px;
      }
    }

    &--outline {
      background-color: $white;
      border: 1px solid $secondary;
      color: $secondary;

      img {
        transition: all .2s;
        filter: invert(85%) sepia(132%) saturate(4711%) hue-rotate(195deg) brightness(60%) contrast(84%)
      }

      &:hover {
        background-color: $secondary;
        color: $white;

        img {
          filter: invert(88%) sepia(89%) saturate(3%) hue-rotate(262deg) brightness(109%) contrast(97%);
        }
      }
    }
  }

  &__second {
    background-color: $primary !important;
    border: 1px solid $primary !important;
    color: $white !important;
    min-width: 150px;

    &:hover {
      background-color: transparent !important;
      color: $primary !important;

      &:disabled {
        background-color: rgba($primary, .75) !important;
        color: rgba($white, .75) !important;
      }

      img {
        filter: invert(85%) sepia(132%) saturate(4711%) hue-rotate(195deg) brightness(60%) contrast(84%)
      }
    }

    &--outline {
      background-color: transparent;
      border: 2px solid $primary;
      color: $primary;

      img {
        transition: all .2s;
        filter: invert(85%) sepia(132%) saturate(4711%) hue-rotate(195deg) brightness(60%) contrast(84%)
      }

      &:hover {
        background-color: $primary;
        color: $white;

        img {
          filter: invert(88%) sepia(89%) saturate(3%) hue-rotate(262deg) brightness(109%) contrast(97%);
        }
      }
    }
  }

  &__third {
    &--outline {
      background-color: transparent;
      border: 2px solid #DBEAF2;
      color: $secondary;

      img {
        transition: all .2s;
        filter: invert(15%) sepia(22%) saturate(4761%) hue-rotate(195deg) brightness(100%) contrast(94%);
      }

      &:hover {
        background-color: #DBEAF2;
        color: $secondary;

        img {
          filter: invert(88%) sepia(89%) saturate(3%) hue-rotate(262deg) brightness(109%) contrast(97%);
        }
      }
    }
  }

  &__black {
    background-color: #707070;
    border: 1px solid #707070;
    color: #707070;

    img {
      transition: all .3s;
    }

    &:hover {
      background-color: transparent;
      color: $black;

      img {
        filter: invert(54%) sepia(19%) saturate(1469%) hue-rotate(40deg) brightness(106%) contrast(85%);
      }
    }

    &--outline {
      background-color: transparent;
      border: 1px solid #707070;
      color: #707070;

      img {
        transition: all .3s;
        filter: invert(0%) sepia(4%) saturate(0%) hue-rotate(331deg) brightness(0%) contrast(105%);
      }

      &:hover {
        background-color: $black;
        color: $white;

        img {
          filter: invert(50%) sepia(5%) saturate(276%) hue-rotate(297deg) brightness(357%) contrast(100%);
        }
      }
    }
  }

  &__white {
    background-color: $white;
    color: $primary;
    padding: 15px 36px;
    font-size: 14px !important;
    border: 1px solid $white;

    @include mq(md) {
      padding: 14px 20px;
      font-size: 12px !important;
    }

    &:hover {
      border: 1px solid $white;
    }

    &--outline {
      background-color: transparent;
      color: $white;
      border: 1px solid $white;

      &:hover {
        background-color: $white;
        color: $black;
      }
    }
  }
}