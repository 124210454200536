h1,
.h1 {
    font-size: 42px;
    line-height: 45px;
    font-weight: 700;
    margin: 0 0 1em 0;
    font-family: Besley, sans-serif;
    
    @include mq(md){
        font-size: 28px;
        line-height: 30px;
    }
}

h2,
.h2 {
    font-size: 32px;
    line-height: 36px;
    font-weight: 600;
    margin: .5em 0;

    @include mq(md) {
        font-size: 24px;
        line-height: 28px;
    }
}

h3,
.h3 {
    font-size: 25px;
    line-height: 29px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}

strong {
    font-weight: 600;
}

small,
small > * {
    font-size: 12px;
    line-height: 14px;
}

a {
	color: $black;
    text-decoration: none;
    
    &:hover {
        color: $black;
    }

    &:focus {
        outline: 0;
    }

    &:hover,
    &:active {
	    outline: 0;
    }
}